import React from 'react'
import { Link } from "gatsby"

import Layout3 from "../components/layout3"
import SEO from "../components/seo"

import { Helmet } from "react-helmet"



import Footer from "../components/footer"

import ContactSection from "../components/home-page/contactSection"


import '../scss/style.scss'

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Breadcrumb from "react-bootstrap/Breadcrumb"

import blog1Img from "../images/MailSculpt-Blog-1.jpg"
import SpotifyBirthday from "../images/SpotifyBirthday.png"
import disneyLive from "../images/disney+-is-now-live.png"
import subjectLines from "../images/subjectLines.png"
import infoGraphic1 from "../images/4-Tips-To-Improve-Your-Email-Campaign.jpg"
import masterclass from "../images/masterclass.jpg"
import masterClassGif from "../images/masterClassGif.gif"


const FourTipsToImproveYourEmailCampaign = () => (

    <Layout3 >

        <SEO
            title="4 Tips To Improve Your Email Campaign"
            description="Actionable tips to get more sales, better engagement, and high ROI from your email campaigns (with examples!)"
            author="Mail Sculpt"
        />


        <Helmet>
            <meta property="og:url" content="https://www.mailsculpt.com/4-tips-to-improve-your-email-campaign/" />
            <meta property="og:image" content="https://www.mailsculpt.com/static/MailSculpt-Blog-1-25b98eefdc226a5e5ecf8cd4abbcdc92.png" />
        </Helmet>



        <Container style={{ marginTop: "120px" }}>

            <Row>
                <Col>
                    <Breadcrumb style={{ fontFamily: "Roboto", backgroundColor: "none" }}>
                        <Breadcrumb.Item><Link to="/" >Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/blogs" >Blogs</Link></Breadcrumb.Item>
                        {/* <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                Library
  </Breadcrumb.Item> */}
                        <Breadcrumb.Item active>4 Tips to Improve Your Email Campaign</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
        </Container>

        <Container style={{ maxWidth: "800px" }}>
            <Row>
                <Col>
                    <img src={blog1Img} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <p style={{ fontFamily: "Roboto" }}>27th July, 2020</p>
                </Col>
            </Row>
            <Row>
                <Col>

                    <h1 style={{ fontFamily: "Roboto" }}>4 Tips to Improve Your Email Campaign</h1>
                    <p style={{ fontFamily: "Roboto" }}>Emails can be used to welcome people when they signup to get your newsletter, onboard new customers, send an apology when delays happen, share offers, thank you notes, and whatnot. In an email, you can put text, gif of your favorite superhero, or a video explaining your product.  </p>
                    <p style={{ fontFamily: "Roboto" }}>Email marketing has been around for a long time, and it is used by almost every industry, from pet grooming to real estate &amp; for every type of communication. But there is still scope for improvement.</p>
                    <p style={{ fontFamily: "Roboto" }}>When the potential for ROI is very high, and use cases are so versatile, you should look at ways to improve your email campaign performance.
</p>
                    <p style={{ fontFamily: "Roboto" }}>So let’s see the top 4 tips to improve your email campaign that you should consider.
</p>


                </Col>
            </Row>

            {/* <Row>
                <Col>
                    <img src={infoGraphic1} />
                </Col>
            </Row> */}

            <Row style={{ marginTop: "50px" }}>

                <Col>     <h2 style={{ fontFamily: "Roboto" }}><strong>Personalize your emails
</strong>
                </h2></Col>
            </Row>


            <Row>
                {/* 
                <Col sm={6} md={6} lg={5} style={{ padding: "0px" }}>
                    <img src={SpotifyBirthday} />
                </Col> */}

                <Col>


                    <p style={{ fontFamily: "Roboto" }}>Personalization is using customers’ data & analytics to send them tailored & contextual messages.
</p>

                    <p style={{ fontFamily: "Roboto" }}>Few examples are:
</p>

                    <ul>
                        <li style={{ fontFamily: "Roboto" }}>Sending birthday emails to subscribers
</li>
                        <li style={{ fontFamily: "Roboto" }}>If you are an app like Spotify, you can send them emails specifically about how they used the app in the past month or year.
</li>
                    </ul>
                </Col>

            </Row>
            <Row>
                <Col>

                    <p style={{ fontFamily: "Roboto" }}>You can segment your audience into categories based on their preferences and send them content that they are more likely to associate with.
</p>
                    <p style={{ fontFamily: "Roboto" }}>A more straightforward form of personalization in content is using the subscriber’s name in the email. You can probably relate it to your own experience. When you read an email that’s addressed directly to you, you give it more attention and thought.
</p>
                    <p style={{ fontFamily: "Roboto" }}>People have become accustomed to getting customized experience, and they gravitate towards brands that recognize them as individuals and not just ‘part of the list.’
</p>
                    <p style={{ fontFamily: "Roboto" }}>So personalization can help you boost revenue & turn your customers into raving fans.
</p>

                </Col>

            </Row>


            <Row className="ml-auto mr-auto text-center">

                <Col>
                    <img src={SpotifyBirthday} style={{ marginBottom: "0px" }} />
                </Col>

            </Row>
            <Row className="text-center">
                <Col> <a href="https://www.spotify.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.spotify.com</a></Col>
            </Row>
            <Row style={{ marginTop: "50px" }}>
                <Col>
                    <h2 style={{ fontFamily: "Roboto" }}><strong>Create CTAs that stand out
</strong></h2>
                </Col>

            </Row>
            <Row>
                <Col>
                    <p style={{ fontFamily: "Roboto", fontWeight: "bold" }}>Placement:
</p>
                    <p style={{ fontFamily: "Roboto" }}>People tend to read from left to right & top to bottom. So place your CTA text or button towards the bottom or to the right of your content.
</p>
                    <p style={{ fontFamily: "Roboto", fontWeight: "bold" }}>Design:
</p>
                    <p style={{ fontFamily: "Roboto" }}>Look at your email design and choose to contrast (but not clashing!) colors for your CTA. Sometimes, you can go simple and use text-based CTAs.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Have enough white space around your CTA to be recognizable even if someone is skimming through your email.
</p>

                </Col>

            </Row>

            <Row>
                <Col>
                    <p style={{ fontFamily: "Roboto", fontWeight: "bold" }}>Copy:
</p>
                    <p style={{ fontFamily: "Roboto" }}>Use a clear and concise text for your CTAs. If you want readers to read your article, write ‘Read the full article here,’ don’t try to sound too smart & use ambiguous copy when you want someone to take action.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Let’s take a look at an example and see how all three elements can be incorporated together.
</p></Col>
            </Row>
            <Row className="text-center" >

                <Col>

                    <img src={disneyLive} style={{ marginBottom: "0px" }} />

                </Col>


            </Row>
            <Row className="text-center">
                <Col> <a href="https://www.disneyplus.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.disneyplus.com</a></Col>
            </Row>
            <Row style={{ marginTop: "50px" }}>
                <Col>
                    <h2 style={{ fontFamily: "Roboto" }}><strong>Stay out of Spam Folder
</strong></h2>
                    <p style={{ fontFamily: "Roboto" }}>Your email might have the best subject line, life-changing knowledge, or cutest dog pic in it, but if it’s going to the spam box (which no one checks ever) & not getting delivered to the subscriber’s inbox then none of that matters.
        </p>
                    <p style={{ fontFamily: "Roboto" }}>You need to realize that you don’t have to send ‘spammy’ or ‘scammy’ content to get your emails marked as spam. If spam filters notice a pattern similar to actual spammers, they will not let your emails into the inbox.
</p>
                    <p style={{ fontFamily: "Roboto" }}>So let’s figure out how to avoid the spam box and land directly into the inbox.
</p>
                    <ul>
                        <li style={{ fontFamily: "Roboto" }}><strong>Don’t create image-only emails:</strong> Sometimes, it might seem like an easy option to create an image of your email content, so you don’t have to deal with HTML or responsiveness issues.
</li>
                    </ul>
                    <p style={{ fontFamily: "Roboto" }}>But note that email clients like Gmail, yahoo, or outlook can’t scan through the image to identify any deceitful text. So scammers tend to disguise their writing as images and send mal-intent content.
</p>
                    <p style={{ fontFamily: "Roboto" }}>It’s impossible to cross-verify whether the sender is legit or scammer, so spam filters tend to adopt a blanket rule of not letting any image-only emails go through to the inbox.
</p>
                    <p style={{ fontFamily: "Roboto" }}>If you ever wished that you can have beautifully created emails without any HTML mess to deal with and have all the best chances to land perfectly into the inbox, Mailsculpt can help you. We design emails that are high converting, responsive, and pass all spam-filter checks. You can know more here.
</p>
                    <ul>
                        <li style={{ fontFamily: "Roboto" }}>Use correct spelling and grammar. You can use online services like Grammarly to help you proofread, catch spelling mistakes, and make your writing look professional.
</li>
                        <li style={{ fontFamily: "Roboto" }}>Link only to legit sites with good domain reputation. Avoid shortening your links when you hyperlink a text, button, or image. Link shortening services are used by spammers who don’t want you to see the actual URL, so these services are blacklisted/blocked by mailbox providers. If your email also has such a link shortener, your email would be blocked too.
</li>
                        <li style={{ fontFamily: "Roboto" }}>Avoid using all caps or exclamations in your subject lines. ! IT FEELS LIKE YOU ARE TRYING TO DISTRACT THE READER from other vital messages in their inbox… and that’s a behavior pattern by spammers.
</li>
                        <li style={{ fontFamily: "Roboto" }}>Lastly, avoid manipulative / too-good-to-be-true subject lines.
</li>
                    </ul>


                </Col>

            </Row>
            <Row style={{ marginTop: "50px" }}>
                <Col>
                    <h2 style={{ fontFamily: "Roboto" }}><strong>Create Responsive Emails
</strong></h2>
                    <p style={{ fontFamily: "Roboto" }}>Responsive email adjusts its format according to the device it is viewed on, whether it is mobile, desktop of any size, iPad, etc. The information & functionality remains the same, only the composition changes.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Responsive design is no more a ‘good to have’ feature. It is a must-have. And that’s not surprising because 50%+ mobiles are now open on mobile.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Imagine if your email from the desktop is precisely copied to your mobile. It will probably have large images, misaligned text, and unidentifiable CTA.
</p>
                    <p style={{ fontFamily: "Roboto" }}>If the user is doing any extra work, like zooming in and out, to view the email, this will frustrate them and not be very engaged with your actual message. Hence leading to no return on efforts, time, or money you invested in those emails.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Let’s look at this beautiful example of an email by Masterclass.com.
                    </p>
                </Col>
            </Row>
            <Row className="text-center">
                <Col >
                    <img src={masterclass} style={{ maxWidth: "500px", marginBottom: "0px" }} />
                </Col>
            </Row>
            <Row className="text-center">
                <Col> <a href="https://www.masterclass.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.masterclass.com</a></Col>
            </Row>

            <Row style={{ marginTop: "20px" }}>
                <Col>

                    <p style={{ fontFamily: "Roboto" }}>This is the desktop version.
</p>
                    <p style={{ fontFamily: "Roboto" }}>And here, you can see how text and placement adjust in mobile.
</p>
                </Col>
            </Row>

            <Row className="text-center">
                <Col >
                    <img src={masterClassGif} style={{}} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <p style={{ fontFamily: "Roboto" }}>Before you start worrying about hiring designers, coders, project managers, and buying expensive tools to create each email you send, you should check us out. Our one-stop solution will get you custom made, engaging &amp; high-converting emails.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Here at Mail Sculpt, we have a modular approach to creating email templates. Meaning, we build one template with a whole lot of components that can be swapped in / out to create variation according to your industry, type of email, or use case. If you wish to know more, <a href="javascript:$zopim.livechat.window.show();" style={{ fontWeight: "bold" }}>drop us a line</a>.
</p>

                </Col>

            </Row>
            <Row style={{ marginTop: "50px", marginBottom: "80px" }}>
                <Col>
                    <h2 style={{ fontFamily: "Roboto" }}><strong>Wrap Up
</strong></h2>
                    <p style={{ fontFamily: "Roboto" }}>Email marketing is all about optimizing your campaign performance, whether through content or design, while treating your email subscribers as individuals. Keep track of your key metrics, and if you can get a steady growth by following the above-mentioned best practices, your time and efforts would be worthwhile.
</p>

                </Col>
            </Row>
        </Container >

        <ContactSection />

        <Footer />




    </Layout3 >

)

export default FourTipsToImproveYourEmailCampaign